import { browser } from '$app/environment';
import { writable, type Writable } from 'svelte/store';

export const storageError: Writable<boolean> = writable(false);

export const handleStorageError = (error: unknown) => {
  if (
    error instanceof Error &&
    error.name === 'SecurityError' &&
    (error.message.includes('localStorage') ||
      error.message.includes('sessionStorage'))
  ) {
    storageError.set(true);
  }
};

class LocalStorage {
  private safeLocalStorage: Storage | undefined;
  constructor() {
    this.safeLocalStorage = this.getLocalStorage();
  }

  private getLocalStorage = (): Storage | undefined => {
    let storage: Storage | undefined = undefined;

    try {
      if (browser) {
        storage = window.localStorage;
      }
    } catch (error) {
      handleStorageError(error);
    }

    return storage;
  };

  public setItem = (k: string, v: string) => {
    this.safeLocalStorage?.setItem(k, v);
  };

  public getItem = (k: string): string | null => {
    const value = this.safeLocalStorage?.getItem(k);
    return value ?? null;
  };

  public removeItem = (k: string) => {
    this.safeLocalStorage?.removeItem(k);
  };
}

export const safeLocalStorage = new LocalStorage();
